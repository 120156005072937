import { Box, BoxProps, styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { FullScreenMediaContextProvider } from 'shared/lib/components/media/FullScreenMediaContextProvider'
import Theme from 'shared/lib/theme/Theme'
import { primaryColor } from 'shared/lib/theme/constants'
import { churchRepository, givingRepository } from '..'
import backgroundImageSmall from '../assets/images/bg-small.png'
import backgroundImageLarge from '../assets/images/bg.png'
import { ActivityIndicator } from '../components/ActivityIndicator'
import { Charity } from '../model/Charity'
import { Church } from '../model/Church'
import { Path } from '../model/Path'
import { PaymentStatus } from '../model/PaymentStatus'
import { useQuery } from '../utilities/Utils'
import { CallbackFromBankView } from './callbackfrombank/CallbackFromBankView'
import { CharitiesView } from './charities/CharitiesView'
import { Fundraiser } from './fundraiser/Fundraiser'
import { FundraiserView } from './fundraiser/FundraiserView'
import {
    PaymentMethod,
    PaymentMethodProvider,
    SelectedPaymentMethod,
} from './paymentMethods/PaymentMethod'
import { PaymentMethodsView } from './paymentMethods/PaymentMethodsView'
import { MAX_CONTENT_WIDTH } from '../common/constants'
import { ChooseAmountView } from './chooseAmount/ChooseAmountView'
import { PrefilledAmount } from './chooseAmount/PrefilledAmount'

const MAX_DONATION_AMOUNT = 7500

export const Main = () => {
    const navigate = useNavigate()
    const query = useQuery()
    const params = useParams()
    const applicationId = params.applicationId!

    const fundraiserId = query.get('fundraiserId') || undefined
    const transactionId = query.get('id') || undefined

    const [isLoading, setIsLoading] = useState(true)
    const [isStartingTransaction, setIsStartingTransaction] = useState(false)
    const [isPaid, setIsPaid] = useState(false)
    const [canSelectBank, setCanSelectBank] = useState(false)
    const [canFinishDonation, setCanFinishDonation] = useState(false)
    const [error, setError] = useState<Error | undefined>()
    const [church, setChurch] = useState<Church>()
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
    const [charities, setCharities] = useState<Charity[]>([])
    const [fundraiser, setFundraiser] = useState<Fundraiser | undefined>()
    const [prefilledAmount, setPrefilledAmount] = useState<PrefilledAmount | undefined>(
        PrefilledAmount.HUNDRED
    )
    const [enteredAmount, setEnteredAmount] = useState<number | undefined>()
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
        SelectedPaymentMethod | undefined
    >()
    const [paymentMethodsToShowAllProviders, setPaymentMethodsToShowAllProviders] = useState<
        Set<PaymentMethod>
    >(new Set())
    const [amount, setAmount] = useState(0)

    const isCharitiesPage =
        [`/${applicationId}`, `/${applicationId}/`].includes(window.location.pathname) &&
        !fundraiserId

    const navigateToEnterAmountPage = () => {
        navigate(`${Path.CHOOSE_AMOUNT}?fundraiserId=${fundraiserId}`)
    }
    const navigateToPaymentMethodsPage = () => {
        const query = fundraiserId ? `?fundraiserId=${fundraiserId}` : ''
        navigate(`${Path.PAYMENT_METHODS}${query}`)
    }

    const onCharityAmountUpdated = () => {
        const totalAmount = charities.reduce((sum, current) => sum + current.amount, 0)
        const canContinue = totalAmount > 0 && totalAmount <= MAX_DONATION_AMOUNT
        setCanSelectBank(canContinue)
    }

    const onFundraiserAmountChanged = (params: {
        prefilledAmount?: PrefilledAmount
        enteredAmount?: number
    }) => {
        setPrefilledAmount(params.prefilledAmount)
        setEnteredAmount(params.enteredAmount)
    }

    const onPaymentMethodSelected = (
        paymentMethod: PaymentMethod,
        provider?: PaymentMethodProvider
    ) => {
        setSelectedPaymentMethod(new SelectedPaymentMethod(paymentMethod, provider))
        setCanFinishDonation(true)
    }

    const fetchData = async () => {
        try {
            setIsLoading(true)

            const [church, paymentMethods, charities, fundraiser, transaction] = await Promise.all([
                churchRepository.getChurch(applicationId),
                givingRepository.getPaymentMethods(applicationId),
                fundraiserId ? [] : givingRepository.getCharities(applicationId),
                fundraiserId
                    ? givingRepository.getFundraiser(applicationId, fundraiserId)
                    : undefined,
                transactionId
                    ? givingRepository.getTransaction(applicationId, transactionId)
                    : Promise.resolve(undefined),
            ])

            document.title = `${church.name} | Donkey Mobile Giving`

            setIsLoading(false)
            setCanSelectBank(true)
            setChurch(church)
            setPaymentMethods(paymentMethods)
            setCharities(charities)
            setFundraiser(fundraiser)
            setSelectedPaymentMethod(selectedPaymentMethod)
            setCanFinishDonation(selectedPaymentMethod !== undefined)
            setIsPaid(transaction?.paymentStatus === PaymentStatus.PAID)
            setAmount(transaction?.amount || 0)
        } catch (error: any) {
            setError(error)
            setIsLoading(false)
        }
    }

    const getTransaction = async () => {
        if (transactionId === undefined) return

        try {
            setIsLoading(true)
            const transaction = await givingRepository.getTransaction(applicationId, transactionId)

            setIsPaid(transaction?.paymentStatus === PaymentStatus.PAID)
            setAmount(transaction?.amount || 0)
            setIsLoading(false)
        } catch (error: any) {
            setError(error)
            setIsLoading(false)
        }
    }

    const startTransaction = async () => {
        if (selectedPaymentMethod === undefined) return

        const amount = prefilledAmount ?? enteredAmount

        if (!amount) return

        try {
            setIsStartingTransaction(true)

            const response = fundraiser
                ? await givingRepository.donateToFundraiser(
                      applicationId,
                      selectedPaymentMethod,
                      fundraiser,
                      amount
                  )
                : await givingRepository.donateToCharities(
                      applicationId,
                      selectedPaymentMethod,
                      charities
                  )

            window.location.href = response.paymentUrl
        } catch (error: any) {
            setError(error)
            setIsStartingTransaction(false)
        }
    }

    const resetTransaction = () => {
        const fundraiserIdQuery = fundraiserId ? `?fundraiserId=${fundraiserId}` : ''
        window.location.href = `/${applicationId}${fundraiserIdQuery}`
    }

    const resetStartingTransaction = useCallback(() => {
        setIsStartingTransaction(false)
    }, [])

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.addEventListener('blur', resetStartingTransaction)

        return () => {
            window.removeEventListener('blur', resetStartingTransaction)
        }
    }, [resetStartingTransaction])

    Theme.palette.primary = Theme.palette.augmentColor({
        color: {
            main: church?.color ?? primaryColor,
        },
    })

    if (isLoading) {
        return <ActivityIndicator isFullScreen />
    }

    if (!church) {
        return <ErrorHandler error={error} />
    }

    return (
        <Box display="flex" position="relative">
            <LeftContainer hasBackgroundImage={isCharitiesPage}>
                <ContentContainer>
                    <Routes>
                        <Route
                            index
                            element={
                                fundraiser ? (
                                    <FullScreenMediaContextProvider>
                                        <FundraiserView
                                            church={church}
                                            fundraiser={fundraiser}
                                            onContinueButtonClicked={navigateToEnterAmountPage}
                                        />
                                    </FullScreenMediaContextProvider>
                                ) : (
                                    <CharitiesView
                                        canContinue={canSelectBank}
                                        church={church}
                                        charities={charities}
                                        onCharityAmountChanged={onCharityAmountUpdated}
                                        onContinueButtonClicked={navigateToPaymentMethodsPage}
                                    />
                                )
                            }
                        />

                        {fundraiser && (
                            <Route
                                path={Path.CHOOSE_AMOUNT}
                                element={
                                    <ChooseAmountView
                                        isLoading={isStartingTransaction}
                                        canContinue={
                                            (!!prefilledAmount || !!enteredAmount) &&
                                            (enteredAmount ?? 0) < MAX_DONATION_AMOUNT
                                        }
                                        church={church}
                                        fundraiser={fundraiser}
                                        prefilledAmount={prefilledAmount}
                                        enteredAmount={enteredAmount}
                                        onBackButtonClicked={() => navigate(-1)}
                                        onAmountChanged={onFundraiserAmountChanged}
                                        onContinueButtonClicked={navigateToPaymentMethodsPage}
                                    />
                                }
                            />
                        )}

                        <Route
                            path={Path.PAYMENT_METHODS}
                            element={
                                <PaymentMethodsView
                                    isStartingTransaction={isStartingTransaction}
                                    canContinue={canFinishDonation}
                                    church={church}
                                    paymentMethods={paymentMethods}
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    paymentMethodsToShowAllProviders={
                                        paymentMethodsToShowAllProviders
                                    }
                                    onBackButtonClicked={() => navigate(-1)}
                                    onShowAllProvidersClicked={(paymentMethod) => {
                                        setPaymentMethodsToShowAllProviders(
                                            new Set(
                                                paymentMethodsToShowAllProviders.add(paymentMethod)
                                            )
                                        )
                                    }}
                                    onPaymentMethodSelected={onPaymentMethodSelected}
                                    onContinueButtonClicked={startTransaction}
                                />
                            }
                        />

                        <Route
                            path={Path.CALLBACK_FROM_BANK}
                            element={
                                <CallbackFromBankView
                                    church={church}
                                    amount={amount}
                                    isPaid={isPaid}
                                    onBackButtonClicked={resetTransaction}
                                    onTryAgainButtonClicked={getTransaction}
                                />
                            }
                        />
                    </Routes>
                </ContentContainer>
            </LeftContainer>

            <RightContainer />

            <ErrorHandler error={error} />
        </Box>
    )
}

const LeftContainer = styled(
    ({ hasBackgroundImage, ...properties }: BoxProps & { hasBackgroundImage: boolean }) => (
        <Box {...properties} />
    )
)(({ theme, hasBackgroundImage }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    backgroundImage: hasBackgroundImage ? `url(${backgroundImageSmall})` : undefined,

    [theme.breakpoints.up('phablet')]: {
        background: 'none',
        overflowY: 'auto',
        height: '100vh',
    },

    [theme.breakpoints.up('desktop')]: {
        width: `calc(50% + 85px)`,
    },
}))

const RightContainer = styled(Box)(({ theme }) => ({
    display: 'none',

    [theme.breakpoints.up('desktop')]: {
        display: 'block',
        height: '100vh',
        width: '50%',
        position: 'absolute',
        right: 0,
        top: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'top left',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImageLarge})`,
    },
}))

const ContentContainer = styled(Box)(({ theme }) => ({
    width: '100%',

    [theme.breakpoints.up('phablet')]: {
        maxWidth: MAX_CONTENT_WIDTH,
    },
}))
